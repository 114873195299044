import React from 'react'
import SEO from "../../components/seo"
import AuthLayout from '../../components/Layout/AuthLayout'
import Register from '../../components/Layout/AuthLayout/Register'

const register = () => {
    return (
        <>
        <SEO title="Home" />
        <AuthLayout>
            <Register />
        </AuthLayout>
            
        </>
    )
}

export default register
