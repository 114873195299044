import React, { useState, useContext } from "react"
import { Formik, Form, ErrorMessage } from "formik"
import FormErrors from "../../../FormErrors"
import * as Yup from "yup"
import {
  FormControl,
  Box,
  TextField,
  Typography,
  FormControlLabel,
  Grid,
  useMediaQuery,
  Checkbox,
} from "@material-ui/core"
import Button from "../../../Button"
import clsx from "clsx"
// import ReCAPTCHA from "react-google-recaptcha"
import { Link } from "gatsby"
import { useTheme } from "@material-ui/styles"
import { useStyles } from "./style"
import AuthContext from "../../../../context/auth/authContext"

const Register = () => {
  const classes = useStyles()
  const [passwords, showPasswords] = useState({
    password1: false,
    password2: false,
  })

  const [verified, setVerified] = useState(true)
  const [isReadPolicy, setIsReadPolicy] = useState(false)
  const [showPasswordError, setShowPasswordError] = useState(false)
  // const { reCaptchaKey } = process.env

  const { password1, password2 } = passwords
  const authContext = useContext(AuthContext)

  const { register, user, loading } = authContext

  const handleClickShowPassword = (name, value) => {
    showPasswords({ ...passwords, [name]: value })
  }

  const handleMouseDownPassword = e => {
    e.preventDefault()
  }

  const passwordError =
    "Password must contain at least one Uppercase, one Lowercase, one Number, one special character and not less than 8 characters"

  const checkPassword = value => {
    setShowPasswordError(true)
    if (
      value.length > 7 &&
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]/.test(value)
    ) {
      setShowPasswordError(false)
    }
  }

  const initialValues = {
    firstName: user !== null ? user.firstName : "",
    lastName: user !== null ? user.lastName : "",
    email: user !== null ? user.email : "",
    phoneNumber: user !== null ? user.phoneNumber : "",
    password: user !== null ? user.password : "",
    password2: user !== null ? user.password : "",
    // isReadPolicy,
  }

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .test({
        name: "validator-custom-name",
        test: function (value) {
          return /\s/.test(value)
            ? this.createError({
                message: "First Name should have no space",
                path: "firstName",
              })
            : true
        },
      })
      .test({
        name: "validator-custom-name",
        test: function (value) {
          return /\d/.test(value)
            ? this.createError({
                message: "Invalid name",
                path: "firstName",
              })
            : true
        },
      })
      .required("First name is empty"),
    lastName: Yup.string()
      .test({
        name: "validator-custom-name",
        test: function (value) {
          return /\s/.test(value)
            ? this.createError({
                message: "last Name should have no space",
                path: "lastName",
              })
            : true
        },
      })
      .test({
        name: "validator-custom-name",
        test: function (value) {
          return /\d/.test(value)
            ? this.createError({
                message: "Invalid name",
                path: "lastName",
              })
            : true
        },
      })
      .required("Last name is empty"),
    email: Yup.string()
      .email("Invalid email format!")
      .required("Email is empty"),
    phoneNumber: Yup.string()
      .min(9, "Phone Number must not be less than 9 characters")
      .test({
        name: "validator-custom-name",
        //eslint-disable-next-line object-shorthand
        test: function (value) {
          return /\s/.test(value)
            ? this.createError({
                message: "Phone Number should have no space",
                path: "phoneNumber",
              })
            : true
        },
      })
      .test({
        name: "validator-custom-name",
        //eslint-disable-next-line object-shorthand
        test: function (value) {
          return /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value)
            ? this.createError({
                message: "Avoid special characters",
                path: "phoneNumber",
              })
            : true
        },
      })
      .matches(
        // /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Enter a valid phone number"
      )
      .required("Phone Number is empty"),
    // password: Yup.string()
    // .required("Password is empty")
    // .matches(
    //   "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,10}$",
    // ),
    password: Yup.string()
      .required("Password is empty")
      .min(8, passwordError)
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]/,
        passwordError
      ),
    password2: Yup.string()
      .required("Re-enter Password")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
    isReadPolicy: Yup.boolean().oneOf(
      [true],
      "Privacy and policy must be read"
    ),
  })

  const onSubmit = async values => {
    const { firstName, lastName, email, password2 } = values
    checkPassword(values.password)
    values = {
      firstName: firstName.charAt(0).toUpperCase() + firstName.slice(1),
      lastName: lastName.charAt(0).toUpperCase() + lastName.slice(1),
      userName: email,
      confirmPassword: password2,
      ...values,
    }
    delete values.password2
    register(values)
  }

  const theme = useTheme()
  const isMobileOrTab = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    // <Container >
    <Box marginBottom={"2rem"}>
      <Typography align="center" className={classes.title}>
        Create an Account
      </Typography>
      <Typography align="center" className={classes.subtitle}>
        Its quick and easy
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched, values, getFieldProps, setFieldValue }) => (
          <Form noValidate autoComplete="off" className={classes.form}>
            <Grid container spacing={isMobileOrTab ? 0 : 3}>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl className={classes.formControl}>
                  <Typography className={classes.label}>First Name</Typography>
                  <TextField
                    name="firstName"
                    id="firstName"
                    {...getFieldProps("firstName")}
                    variant="outlined"
                    size="small"
                  />
                  <ErrorMessage name="firstName" component={FormErrors} />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl className={classes.formControl}>
                  <Typography className={classes.label}>Last Name</Typography>
                  <TextField
                    name="lastName"
                    id="lastName"
                    {...getFieldProps("lastName")}
                    variant="outlined"
                    size="small"
                  />
                  <ErrorMessage name="lastName" component={FormErrors} />
                </FormControl>
              </Grid>
            </Grid>

            <FormControl className={classes.formControl}>
              <Typography className={classes.label}>Email Address</Typography>
              <TextField
                name="email"
                id="email"
                {...getFieldProps("email")}
                variant="outlined"
                size="small"
              />
              <ErrorMessage name="email" component={FormErrors} />
            </FormControl>

            <FormControl className={classes.formControl}>
              <Typography className={classes.label}>Phone Number</Typography>
              <TextField
                name="phoneNumber"
                id="phoneNumber"
                {...getFieldProps("phoneNumber")}
                error={errors.phoneNumber && touched.phoneNumber ? true : false}
                //   onChange={
                //     e => {
                //         let val = e.target.value
                //         val = val.replace(/-/g, "")
                //         setFieldValue('phoneNumber', val.replace(/\s/g,''))
                //     }

                // }
                variant="outlined"
                size="small"
              />
              <ErrorMessage name="phoneNumber" component={FormErrors} />
            </FormControl>

            <FormControl className={classes.formControl}>
              <Typography className={classes.label}>Password</Typography>
              <TextField
                name="password"
                id="password1"
                onBlur={e => {
                  checkPassword(e.target.value)
                }}
                onChange={e => {
                  setFieldValue("password", e.target.value)
                  checkPassword(e.target.value)
                }}
                // {...getFieldProps("password")}
                variant="outlined"
                type={password1 ? "text" : "password"}
                size="small"
                InputProps={{
                  endAdornment: (
                    <Typography
                      position="end"
                      className={clsx(classes.actionText, classes.text)}
                      onClick={() =>
                        handleClickShowPassword("password1", !password1)
                      }
                      onMouseDown={handleMouseDownPassword}
                    >
                      {password1 ? "Hide" : "Show"}
                    </Typography>
                  ),
                }}
              />
              {/* To make sure user is always aware of the combination required (Trying to reduce bounce rate - Business suggested) */}
              {showPasswordError && (
                <Typography style={{ color: "#FD2D55", fontSize: "13px" }}>
                  {passwordError}
                </Typography>
              )}
              {/* <ErrorMessage name="password" component={FormErrors} /> */}
            </FormControl>

            <FormControl className={classes.formControl}>
              <Typography className={classes.label}>
                Confirm Password
              </Typography>
              <TextField
                name="password2"
                id="password2"
                {...getFieldProps("password2")}
                variant="outlined"
                type={password2 ? "text" : "password"}
                size="small"
                InputProps={{
                  endAdornment: (
                    <Typography
                      position="end"
                      className={clsx(classes.actionText, classes.text)}
                      onClick={() =>
                        handleClickShowPassword("password2", !password2)
                      }
                      onMouseDown={handleMouseDownPassword}
                    >
                      {password2 ? "Hide" : "Show"}
                    </Typography>
                  ),
                }}
              />
              <ErrorMessage name="password2" component={FormErrors} />
            </FormControl>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    {...getFieldProps("isReadPolicy")}
                    name="isReadPolicy"
                    color="primary"
                    checked={values.isReadPolicy}
                    onChange={() => {
                      setFieldValue("isReadPolicy", !values.isReadPolicy)
                      setIsReadPolicy(!isReadPolicy)
                    }}
                  />
                }
                label={
                  <Box>
                    <Typography variant="caption" display="inline">
                      By signing up, you agree to our
                    </Typography>{" "}
                    <Link
                      to="/privacy-policy"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        display="inline"
                        color="primary"
                        variant="caption"
                      >
                        Data protection policy agreement
                      </Typography>
                    </Link>
                  </Box>
                }
              />
              <ErrorMessage name="isReadPolicy" component={FormErrors} />
            </FormControl>
            {/* <Box className={classes.formControl}>
              <ReCAPTCHA
                sitekey={reCaptchaKey}
                onChange={() => setVerified(!verified)}
              />
            </Box> */}
            <FormControl className={classes.submit}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading || !verified || !isReadPolicy}
                loading={loading}
              >
                Sign Up
              </Button>
              <Box align="center">
                <Typography display="inline" className={classes.text}>
                  Already have an account?
                </Typography>{" "}
                &nbsp;
                <Link to="/user/login" style={{ textDecoration: "none" }}>
                  <Typography
                    display="inline"
                    className={clsx(classes.actionText, classes.text)}
                  >
                    Login
                  </Typography>
                </Link>
              </Box>
            </FormControl>
          </Form>
        )}
      </Formik>
    </Box>
    // </Container>
  )
}

export default Register
